@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@200;300;400;500;600;700;800&display=swap");

body {
  font-family: "JetBrains Mono", monospace;
  background-color: #fdb929;
}

.app {
  /* overflow-x: hidden; */
}

img {
  width: 100%;
}

a {
  outline: none !important;
  cursor: pointer;
  text-decoration: none !important;
  color: inherit !important;
}

button,
input {
  outline: none !important;
}

.bg-btn-gradient {
  background: linear-gradient(270deg, #fdb929 0%, rgba(217, 217, 217, 0) 100%);
}

.bg-btn-gradient-blue {
  background: linear-gradient(270deg, #2f88f0 0%, rgba(217, 217, 217, 0) 100%);
}

.slide-in {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #f74b37;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #f74b37;
  transform-origin: top;
}

.react-responsive-modal-container {
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.react-responsive-modal-modal {
  /* height: 90%; */
  /* max-height: 1250px; */
  -webkit-overflow-scrolling: touch;
  /* top: 50%; */
  /* transform: translateY(-50%); */
}
